.addProductContainer {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  
    h2 {
      font-size: 24px;
      margin-bottom: 20px;
    }
  
    form {
      display: flex;
      flex-direction: column;
  
      label {
        margin-bottom: 10px;
  
        input,
        textarea {
          width: 100%;
          padding: 8px;
          font-size: 16px;
        }
  
        textarea {
          resize: vertical;
        }
      }
  
      button {
        background-color: #3498db;
        color: #fff;
        padding: 10px;
        cursor: pointer;
        border: none;
        font-size: 18px;
        transition: background-color 0.3s;
  
        &:hover {
          background-color: #2980b9;
        }
      }
    }
  }