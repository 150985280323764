.imageContainer {
    width: 100%;
    height: 400px; /* встановлюємо висоту контейнера зображення */
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 10px;
    background-color: #726e6e;
  }
  
  .carouselImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .carouselImage:hover {
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  }
  