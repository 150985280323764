



.banner {
  width: 100%; /* Змінено з 100vw на 100% для повної ширини екрана */
  height: auto;
  position: relative;
  left: 0;
}

@media screen and (max-width: 480px) {
  .banner {
    /* Ліве зміщення відсутнє на малих екранах */
    left: 0;
  }
}

.sortContainer {
 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 20px 0;

  & > div {
  

    h3 {
      margin-bottom: 10px;
    }

    select {
      width: 100%;
      padding: 10px;
      font-size: 16px;
    }
  }
}

.listContainer {
  margin-top: 20px;
  position: relative;
  display: flex;
  padding: 0px 45px 0 45px;
  @media screen and (max-width: 1015px) { 
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 0 auto;
    padding: 0px 5px 0 5px;
  }
}
.sortType {
  display: flex;
  justify-content: center;
  font-optical-sizing: auto;
  font-style: normal;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-weight: 500;
  font-size: large;
}
.sortQuantity {
  margin-bottom: 20px;
  // position: absolute;
  // top: 0;
  // left: 0;
  width: 15%;
  @media screen and (min-width: 1015px) { 
    .quantitySelect{
      display: none;
    }}

  @media screen and (max-width: 1015px) { 
    .check{
      display: none;
    }
    width: 100%;
    h3{
      margin-right: 15px;
    }
    label:not(:last-child){
      margin-right: 10px;
    }
  }
}
.quantitySelect{
  margin: 0 auto;
  display: flex;
  width: 80%;
}
.listBox{
  width: 85%;
}
.sortQuantity h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.sortQuantity label {
  display: block;
  margin-bottom: 5px;
}

.sortQuantity input[type="checkbox"] {
  margin-right: 5px;
}

.sortQuantity input[type="checkbox"] {
  -webkit-appearance: none; /* Вимкнути стандартний вигляд */
  -moz-appearance: none;
  appearance: none;
  width: 16px; /* Розміри чекбоксу */
  height: 16px;
  border: 1px solid #000; /* Рамка чекбоксу */
  border-radius: 3px; /* Закругленість кутів */
  outline: none; /* Видалення контурів при фокусі */
  position: relative; /* Позиціонування для позначки */
  cursor: pointer; /* Змінює курсор при наведенні */
}

/* Стилі для випуклого коліру всередині чекбоксу */
.sortQuantity input[type="checkbox"]::before {
  content: ""; /* Зміст необхідний для ::before */
  position: absolute; /* Абсолютне позиціонування */
  top: 50%; /* Верхня межа */
  left: 50%; /* Ліва межа */
  transform: translate(-50%, -50%); /* Переміщення на 50% відносно відповідних осей */
  width: 90%; /* Розміри випуклого коліру */
  height: 90%;
  background-color: #ff5100; /* Колір випуклого коліру */
  
  display: none; /* Початково випуклий колір не відображається */
}

/* Стилі для активного стану чекбоксу */
.sortQuantity input[type="checkbox"]:checked::before {
  display: block; /* Показати випуклий колір, коли чекбокс відмічено */
}
.btnCreate {
  position: absolute;
  top: 20vh;
  color: #ffffff;
  background-color: #00ff15;
  font-weight: 600;
  text-transform: uppercase;
  height: 45px;
  font-size: 20px;
  z-index: 10;
  border: none;
  padding: 7px 30px;
  text-decoration: none;
  right: 70vw;
}
.titleBox{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
}
.title{
  font-weight: 700;
  font-size: 30px;
  margin-bottom: 40px;
}
.titleUnder{
  width: 100px;
  height: 3px;
  background-color: #ff5100;
}

.textMarquee{
margin: 0 10px 0 10px;
}

.dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #ffffff; /* Колір заповнення кола */
  border-radius: 50%; /* Закругленість, щоб отримати коло */
  display: flex;
}
.quantitySelect {
  background-image: linear-gradient(270deg, #f8f8f8, #ff51002e);
  background-size: 400% 400%;
  color: black; /* Колір тексту всередині селекту */
  border: solid 1px black; 
  padding: 8px; /* Опціонально: внутрішній відступ */
  border-radius: 4px; /* Опціонально: заокруглені краї */
  font-size: 16px; /* Розмір тексту */
  animation: animateBackground 2s ease infinite;
}

@keyframes animateBackground {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}