.Basket {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .form {
    flex: 1;
    background-color: #f1f1f1;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    color: #000;
    font-family: 'Mulish', sans-serif;

    .input {
      width: 100%;
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ccc;
      border-radius: 4px;
    }

    .btmForm {
      width: 100%;
      padding: 10px;
      background-color: #28a745;
      border: none;
      color: white;
      cursor: pointer;
      border-radius: 4px;

      &:hover {
        background-color: #218838;
      }
    }
  }

  .orderBox {
    flex: 2;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    h2 {
      margin-bottom: 20px;
    }

    .orderItem {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

      height: 120px;
      @media screen and (max-width: 480px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 130px;
      }
      &:last-child {
        border-bottom: none;
      }

      .productImage {
        width: 50px;
        height: 50px;
        object-fit: cover;
        margin-right: 10px;
        border-radius: 4px;
      }

      .productTitle {
        flex: 2;
        display: flex;
        flex-direction: column;
        p {
          margin: 0;
          font-size: 0.9em;
        }

        .flavor {
          color: #666;
          font-size: 0.8em;
        }
        .productName {
          font-size: large;
          font-weight: 600;
          @media screen and (max-width: 480px) {
            width: 150px;
            font-size: 15px;
          }
        }
      }

      .quantityControl {
        display: flex;
        align-items: center;
        gap: 5px;
        flex: 1;
        justify-content: center;

        .btnControl {
          background-color: transparent;
          border: 1px solid #454546;
          color: #454546;
          cursor: pointer;
          border-radius: 50%;
          padding: 6px;
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          line-height: 1;
          &:disabled {
            border-color: #ccc;
            color: #ccc;
            cursor: not-allowed;
          }
          @media screen and (max-width: 480px) {
            width: 20px;
            height: 20px;
          }
        }

        span {
          padding: 0 10px;
        }
      }

      .totalPrice {
        width: 80px;
        text-align: right;
        flex: 1;
        margin: 0 10px 0 0;
      }

      .btnDel {
        background-color: transparent;
        border: 1px solid #dc3545;
        color: #dc3545;
        cursor: pointer;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        line-height: 1;
        fill: red;
        padding: 6px;
        @media screen and (max-width: 480px) {
          position: absolute;
          top: 10px;
          right: 10px;
        }
      }
    }
  }
}
.price {
  font-size: 19px;
  font-weight: 600;
  color: #000;
}
.quantBtn {
  display: flex;
}
.infoTitle {
  display: flex;
}
