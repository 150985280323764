.thanks-container {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  .thanks-title {
    font-size: 28px;
    font-weight: bold;
    color: #ff5100; /* Оранжевий колір */
    margin-bottom: 10px;
  }
  
  .thanks-message {
    font-size: 20px;
    color: #000000; /* Чорний колір */
    margin-top: 0;
  }
  
  /* Додатковий стиль для контейнера, який може змінити фон */
  .thanks-container {
    background-color: #ffffff; /* Білий фон */
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Тінь */
  }
  .btnSignTG {
    color: #ffffff;
    border: 2px solid #ffffff;
    background: linear-gradient(135deg, #0088cc 0%, #3db2ff 100%);
    font-weight: 600;
    text-transform: uppercase;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
    height: 45px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
  
    @media screen and (max-width: 401px) {
      font-size: 12px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }