.product-detail {
  max-width: 600px;
  margin: 0 auto;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  h2 {
    text-align: center;
    color: #333;
    margin-bottom: 1.5rem;
    
  }

  p {
    font-size: 1rem;
    color: #555;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .image {
    max-width: 100%;
    height: auto;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: block;
    margin: 0 auto 1rem auto;
  }

  .button {
    display: block;
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: #fff;
    text-align: center;
    border: none;
    border-radius: 3px;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;

    &:hover {
      background-color: #0056b3;
    }
  }

  .loading {
    text-align: center;
    font-size: 1.25rem;
    color: #333;
  }
}
.imgDet {
  width: 500px;
  @media screen and (max-width: 480px) {
    width: 180px;
   }
}
.title{
  @media screen and (max-width: 480px) {
    font-size: 15px;
   }
}
.name{
  @media screen and (max-width: 480px) {
    font-size: 15px;
   }
}
.listDesc{
  @media screen and (max-width: 480px) {
    font-size: 10px;
   }
}
.detBox{
  display: flex;
  padding-top: 25px;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
   }
}
.btnBuy{
  color: #ffffff;
  background-color: #ff5100;
  font-weight: 600;
  text-transform: uppercase;
  height: 45px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
}
.boxCount{
  display: inline-flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    vertical-align: top;
    margin: 0 4px 8px 0;
    border: 1px solid #777;
    font-size: 14px;
    height: 45px;
    background-color: #fff;
    box-sizing: border-box;
}
.btnInc{
  min-width: 25px;
  width: 25px;
  height: 25px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  border: none;
  background-color: rgba(0, 0, 128, 0);
}
.btnNumb{
  text-align: center;
    width: 30px;
    height: 30px;
    padding: 0;
    vertical-align: top;
    border: none;
    background-color: transparent;
    color: #000;
}
select{
  border: 1px #ddd solid;
  background: #f8f8f8;
  color: #000;
  box-sizing: border-box;
  cursor: pointer;
  padding: 2px 10px 2px 10px;
  border-radius: 5px;
 
  appearance: none;
  @media screen and (max-width: 480px) {
    width: 100%;
    font-size: 13px;
   }
}
.btnBuyCount{
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 480px) {
    width: 230px;
   }
}
.btnCor{
  color: #ffffff;
  background-color: #00ff2a;
  font-weight: 600;
  text-transform: uppercase;
  height: 45px;
    font-size: 14px;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
    @media screen and (max-width: 480px) {
      width: 230px;
     }
}
.prodInfo{
  margin-left: 20px;
  @media screen and (max-width: 480px) {
    margin: 0;
   
   }
  h2 {
    
    @media screen and (max-width: 480px) {
      font-size: 18px;
      font-weight: 700;
     
     }
  }
}

.price{
  font-weight: 700;
}